.hlstd--spinner-container, .hlstd--spinner-container *, .hlstd--spinner-logo {
	box-sizing: content-box;
}

.hlstd--spinner-container {
  width: 88px;
  height: 88px;
}

.hlstd--spinner-inner {
  position: absolute;
  z-index: 2010;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  border: 1px solid #FD174F;
  border-radius: 50%;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: spin-that-logo 2000ms infinite ease-in-out;
}

.hlstd--spinner-outer {
  position: absolute;
  z-index: 2000;
  top: 0px;
  left: 0px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  margin: 0 auto;
  border: 2px solid rgba(160, 160, 160, 0.25);
  border-radius: 50%;
}

.hlstd--spinner-logo {
	position: absolute;
	top: calc(50% + 10px);
	left: calc(50% + 2px);
	width: 100%;
	transform: translate(-50%, -50%);
	text-align: center;
	color: #8bc34a;
	font-size: 40px;
	font-weight: 700;
	letter-spacing: -3px;
}

.hlstd--spinner-footer {
  position: absolute;
  top: calc(50% + 100px);
  width: 100vw;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}

@keyframes spin-that-logo {
  0% {
    transform: scale(1, 1) rotate(0deg);
  }
  10% {
    transform: scale(1, 1) rotate(90deg);
  }
  20% {
    transform: scale(1.2, 1.2) rotate(90deg);
  }
  30% {
    transform: scale(1.2, 1.2) rotate(180deg);
  }
  50% {
    transform: scale(1.2, 1.2) rotate(180deg);
  }
  60% {
    transform: scale(1.2, 1.2) rotate(270deg);
  }
  80% {
    transform: scale(1.2, 1.2) rotate(270deg);
  }
  85% {
    transform: scale(1, 1) rotate(270deg);
  }
  100% {
    transform: scale(1, 1) rotate(360deg);
  }
}